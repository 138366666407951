import { DateTime } from "luxon";
import { safeCompare } from "./core";

class BatterDate {
  year: number;
  month: number;
  day: number;
  constructor(info?: Date) {
    const date = info || new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;
    this.day = date.getDate();
  }
}

class BatterTime {
  hour: number;
  minute: number;
  second: number;
  constructor(info?: Date) {
    this.hour = info?.getHours() || 0;
    this.minute = info?.getMinutes() || 0;
    this.second = info?.getSeconds() || 0;
  }
}

class BatterDateTime {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  constructor(info?: Date) {
    this.year = info?.getFullYear() || 0;
    this.month = (info?.getMonth() || -1) + 1;
    this.day = info?.getDate() || 0;
    this.hour = info?.getHours() || 0;
    this.minute = info?.getMinutes() || 0;
    this.second = info?.getSeconds() || 0;
  }
}

/**
 * returns a formatted date
 * @param arg date in the format YYYY-MM-DD, JS Date, or BatterDate
 * @param format the format string
 * @param message a default message (optional)
 * @returns a string
 */
const formatDate = (arg: string | Date | BatterDate | null | undefined, format: string, message = "") => {
  let dt: DateTime | null = null;
  if (typeof arg === "string") {
    // if the string is YYYY-MM-DD, then from SQL
    // if the string is HH:mm, then from SQL
    // if the date is a dateTime, then we have to worry about timeZones
    dt = DateTime.fromSQL(arg);
  } else if (arg instanceof Date) {
    dt = DateTime.fromJSDate(arg);
  } else if (arg instanceof BatterDate) {
    dt = DateTime.fromObject(arg);
  }

  return dt?.toFormat(format) || message;
};

type FormatTimeOptions = {
  input?: string;
  defaultValue?: string;
};

/**
 * returns a formatted time
 * @param arg time string in the HH:mm format or BatterTime
 * @param format the format string
 * @param options formatting options.
 * @returns a string
 */
const formatTime = (
  arg: string | Date | BatterTime | null | undefined,
  format: string,
  options: FormatTimeOptions = {}
) => {
  const { defaultValue = "", input = "HH:mm:ss" } = options;
  if (!arg) {
    return defaultValue;
  }

  let dt: DateTime;
  if (typeof arg === "string") {
    dt = DateTime.fromFormat(arg, input);
  } else if (arg instanceof Date) {
    dt = DateTime.fromJSDate(arg);
  } else {
    dt = DateTime.fromObject(arg);
  }

  return dt.toFormat(format) || defaultValue;
};

type FormatTZOptions = {
  defaultValue?: string;
};

const formatTimezone = (arg: string | null | undefined, format: string, options: FormatTZOptions = {}) => {
  const { defaultValue = "" } = options;
  if (!arg) {
    return defaultValue;
  }

  return DateTime.now().setZone(arg).toFormat(format);
};

type DateTimeFormatOptions = {
  /**
   * an optional boolean that turns the result to system zone (default: false)
   */
  toLocal?: boolean;
  /**
   * an optional zone to convert the string to another timeZone
   */
  toZone?: string;
};

/**
 * returns a formatted dateTime
 * @param arg dateTime string in the ISO format, Date, or BatterDateTime
 * @param format the format string
 * @param options format options
 * @returns a string
 */
const formatDateTime = (arg: string | Date | BatterDateTime, format: string, options: DateTimeFormatOptions = {}) => {
  // instance
  let dt: DateTime | null = null;
  const { toLocal = false, toZone } = options;

  // do the formatting
  if (typeof arg === "string") {
    dt = toLocal ? DateTime.fromISO(arg) : DateTime.fromISO(arg, { setZone: !toZone, zone: toZone });
  } else if (arg instanceof Date) {
    dt = DateTime.fromJSDate(arg);
  } else {
    dt = DateTime.fromObject(arg);
  }
  return dt.toFormat(format);
};

const dateFromBatterDate = (arg: BatterDate) => {
  const res = new Date();
  res.setHours(0, 0, 0, 0);
  res.setFullYear(arg.year);
  res.setMonth(arg.month - 1);
  res.setDate(arg.day);
  return res;
};

const dateFromDateAndTime = (date: Date, time: Date) => {
  const res = new Date(date.getTime());
  res.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
  return res;
};

const jsDateFromString = (arg: string, format: string) => DateTime.fromFormat(arg, format).toJSDate();

/**
 * gets a JS Date object from a string
 * @param arg input string
 * @param format optional format
 * @returns JS Date
 */
const dateFromDateString = (arg: string, format = "yyyy-MM-dd") => DateTime.fromFormat(arg, format).toJSDate();

/**
 * gets a JS Date object from a string
 * @param arg input string
 * @param ignoreTz whether or not to ignore the tz in the arg
 * @returns JS Date
 */
const dateFromDateTimeString = (arg: string, ignoreTz = false) => {
  if (ignoreTz) {
    // the arg itself cannot have a timeZone at the end
    const newArg = arg.replace(/[-+]\d{4}$/i, "");
    return jsDateFromString(newArg, "yyyy-MM-dd'T'HH:mm:ss");
  }

  return jsDateFromString(arg, "yyyy-MM-dd'T'HH:mm:ssZZZ");
};

/**
 * gets a JS Date object from a string
 * @param arg input string
 * @returns JS Date
 */
const dateFromTimeString = (arg: string) => {
  const now = formatDate(new Date(), "yyyy-MM-dd");
  return dateFromDateTimeString(`${now}T${arg}`, true);
};

const convertToTimezone = (arg: string | Date, to: string) => {
  const dt = typeof arg === "string" ? DateTime.fromISO(arg, { setZone: true }) : DateTime.fromJSDate(arg);
  return new Date(dt.setZone(to).toFormat("yyyy-MM-dd'T'HH:mm:ss"));
};

const isStringValid = (arg: string | undefined, format: string) => {
  return !!arg && DateTime.fromFormat(arg, format).isValid;
};

/**
 * checks if a string matches the pattern 'M/d/yyyy' & that
 * the actual date is a valid date
 * @param arg the string to check
 * @returns a boolean whther or not the string is a valid date
 */
const isDateStringValid = (arg?: string) => isStringValid(arg, "M/d/yyyy");

/**
 * checks if a string matches the pattern 'h:mm a' & that
 * the actual time is a valid time
 * @param arg the string to check
 * @returns a boolean whther or not the string is a valid time
 */
const isTimeStringValid = (arg?: string) => isStringValid(arg, "h:mm a");

/**
 * checks if a string matches the pattern 'M/d/yyyy h:mm a' & that
 * the actual dateTime is a valid dateTime
 * @param arg the string to check
 * @returns a boolean whther or not the string is a valid dateTime
 */
const isDateTimeStringValid = (arg?: string) => isStringValid(arg, "M/d/yyyy h:mm a");

/**
 * add days to a Date
 * @param date Date
 * @param days number of days
 * @returns Date
 */
const addDays = (date: Date, days: number) => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() + days);
  return copy;
};

/**
 * add years to a Date
 * @param date Date
 * @param years number of years
 * @returns Date
 */
const addYears = (date: Date, years: number) => {
  const copy = new Date(date.getTime());
  copy.setFullYear(date.getFullYear() + years);
  return copy;
};

/**
 * get the date at the start of the week (Sunday)
 * @param date date to check
 * @returns the date at the start of the week
 */
const getStartOfWeek = (date: Date) => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() - date.getDay());
  return copy;
};

/**
 * get the date at the end of the week (Saturday)
 * @param date date to check
 * @returns the date at the end of the week
 */
const getEndOfWeek = (date: Date) => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() + (6 - date.getDay()));
  return copy;
};

/**
 * get the date representing the day of the week of the date argument. For example,
 * if the argument is a Wednesday, and the targetDay is 6, then the date returned
 * will be the following Saturday.
 * @param date date to check
 * @param targetDay this is the day of the week to get the date for
 * @returns the date at the end of the week
 */
const getPrevDayOfWeekOfDate = (date: Date, targetDay: number) => {
  if (targetDay < 0 || targetDay > 6) {
    throw new Error("Invalid targetDay. Must be between 0 and 6");
  }
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() + ((targetDay - date.getDay() - 7) % 7));
  return copy;
};

/**
 * get the date representing the day of the week of the date argument. For example,
 * if the argument is a Wednesday, and the targetDay is 6, then the date returned
 * will be the following Saturday.
 * @param date date to check
 * @param targetDay this is the day of the week to get the date for
 * @returns the date at the end of the week
 */
const getNextDayOfWeekOfDate = (date: Date, targetDay: number) => {
  if (targetDay < 0 || targetDay > 6) {
    throw new Error("Invalid targetDay. Must be between 0 and 6");
  }
  const copy = new Date(date.getTime());
  const diff = (targetDay - date.getDay() + 7) % 7 || 7;
  copy.setDate(date.getDate() + (diff === 0 ? 0 : diff));
  return copy;
};

const getClosestDayOfWeek = (date: Date, dayNum: 0 | 1 | 2 | 3 | 4 | 5 | 6) => {
  if (dayNum === date.getDay()) {
    return date;
  }

  return addDays(date, 7 + (dayNum - date.getDay()));
};

const compareDates = (a: Date | string | null | undefined, b: Date | string | null | undefined) => {
  return safeCompare(a, b, (x, y) => {
    const aDate = typeof x === "string" ? DateTime.fromISO(x) : DateTime.fromJSDate(x);
    const bDate = typeof y === "string" ? DateTime.fromISO(y) : DateTime.fromJSDate(y);
    return aDate.toMillis() - bDate.toMillis();
  });
};

const compareTimes = (a: string | null | undefined, b: string | null | undefined) => {
  return safeCompare(a, b, (x, y) => {
    const aTime = DateTime.fromFormat(x, "HH:mm");
    const bTime = DateTime.fromFormat(y, "HH:mm");
    return aTime.toMillis() - bTime.toMillis();
  });
};

const compareDateTimes = (a: string | null | undefined, b: string | null | undefined) => {
  return safeCompare(a, b, (x, y) => {
    const aDate = DateTime.fromISO(x);
    const bDate = DateTime.fromISO(y);
    return aDate.toMillis() - bDate.toMillis();
  });
};

export {
  addDays,
  addYears,
  BatterDate,
  BatterDateTime,
  BatterTime,
  compareDates,
  compareDateTimes,
  compareTimes,
  convertToTimezone,
  dateFromBatterDate,
  dateFromDateAndTime,
  dateFromDateString,
  dateFromDateTimeString,
  dateFromTimeString,
  formatDate,
  formatDateTime,
  formatTime,
  formatTimezone,
  getClosestDayOfWeek,
  getEndOfWeek,
  getNextDayOfWeekOfDate,
  getPrevDayOfWeekOfDate,
  getStartOfWeek,
  isDateStringValid,
  isDateTimeStringValid,
  isTimeStringValid,
  type DateTimeFormatOptions,
  type FormatTimeOptions,
  type FormatTZOptions
};
