import { type ClassValue, clsx } from "clsx";
import { CronExpressionParser, CronFieldCollection, DayOfWeekRange, HourRange } from "cron-parser";
import { DayOfWeek, DAYS_OF_THE_WEEK } from "@/types/core";
import { joinListGrammatically, titleCase } from "./string";

const cn = (...inputs: ClassValue[]) => {
  return clsx(inputs);
};

const range = (start: number, end?: number) => {
  if (end == null) {
    return Array.from(Array(start).keys());
  }

  return Array.from(Array(end - start).keys()).map(i => i + start);
};

const getOrdinal = (x: number) => {
  switch (x) {
    case 11:
    case 12:
    case 13:
      return x + "th";
    default: {
      const base10 = x % 10;
      if (base10 === 1) {
        return x + "st";
      }
      if (base10 === 2) {
        return x + "nd";
      }
      if (base10 === 3) {
        return x + "rd";
      }
      return x + "th";
    }
  }
};

/**
 * Promise-based version of setTimeOut
 * @param ms number of milliseconds to sleep
 * @returns
 */
const sleep = (ms: number) => new Promise<void>(r => setTimeout(r, ms));

/**
 * check if an object is empty
 * @param obj the object to check
 * @returns true if the object is empty
 */
const isObjectEmpty = (obj: object | null | undefined) => obj == null || Object.keys(obj).length === 0;

const isInfinite = (x: unknown) => !Number.isFinite(x);

const compareValue = <T extends string | number>(a?: T | null, b?: T | null) => {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null && b != null) {
    return -1;
  }

  if (a != null && b == null) {
    return 1;
  }

  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }

  if (typeof a === "number" && typeof b === "number") {
    // special case: handle infinity
    if (isInfinite(a) && !isInfinite(b)) return 1;
    if (!isInfinite(a) && isInfinite(b)) return -1;
    if (isInfinite(a) && isInfinite(b)) return 0;

    // everything else
    return a - b;
  }

  throw Error("types mismatch");
};

const sortDays = (a: DayOfWeek, b: DayOfWeek) => DAYS_OF_THE_WEEK.indexOf(a) - DAYS_OF_THE_WEEK.indexOf(b);

const findParentNodeById = (node: Element | null, id: string) => {
  let curr = node;
  while (curr != null) {
    if (curr.id === id) {
      return curr;
    }
    curr = curr.parentElement;
  }
  return null;
};

const safeCompare = <T>(a: T | null | undefined, b: T | null | undefined, func: (x: T, y: T) => number) => {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null) {
    return -1;
  }

  if (b == null) {
    return 1;
  }

  return func(a, b);
};

type CronType = "unix" | "spring";

class BatterCron {
  private _fields: CronFieldCollection;

  constructor(cron: string) {
    // initialize expression
    const expression = CronExpressionParser.parse(cron.replace(/\?/g, "*"));

    // set the days of the week
    this._fields = CronFieldCollection.from(expression.fields, {
      dayOfWeek: this.modifyDaysOfWeek(expression.fields.dayOfWeek.values)
    });
  }

  get hours(): number[] {
    return this._fields.hour.values;
  }

  set hours(hours: number | number[]) {
    const val = Array.isArray(hours) ? hours : [hours];
    const hasDecimal = val.some(i => !Number.isInteger(i));
    if (hasDecimal) {
      throw new Error("Only integers from 0-23 allowed");
    }

    if (Math.min(...val) < 0 || Math.max(...val) > 23) {
      throw new Error("values not in hour range");
    }

    this._fields = CronFieldCollection.from(this._fields, { hour: val as HourRange[] });
  }

  get daysOfWeek(): number[] {
    return this._fields.dayOfWeek.values;
  }

  set daysOfWeek(daysOfWeek: number | number[]) {
    const val = Array.isArray(daysOfWeek) ? daysOfWeek : [daysOfWeek];
    const hasDecimal = val.some(i => !Number.isInteger(i));
    if (hasDecimal) {
      throw new Error("Only integers from 0-7 allowed");
    }

    if (Math.min(...val) < 0 || Math.max(...val) > 7) {
      throw new Error("values not in days of week range");
    }

    this._fields = CronFieldCollection.from(this._fields, { dayOfWeek: val as DayOfWeekRange[] });
  }

  clone = () => new BatterCron(this.asExpression());

  /**
   * Returns cron as a string
   * @param type CronType ('unix'|'spring')
   * @returns
   */
  asExpression = (type: CronType = "unix") => {
    const prefix = type === "unix" ? "" : "0 ";
    return prefix + this._fields.stringify();
  };

  /**
   * displays a human-readable cron description
   *
   * NOTE: only shows data for hours and the days of the week
   * @returns english string of the cron
   */
  description = () => {
    // get unix expr
    const unixExpr = this.asExpression().split(" ");

    // get hour description
    const hourDesc = this.getHourDescription(unixExpr[1]);

    // get day of week description
    const dayWeekDesc = this.getDaysOfWeekDescription(unixExpr[4]);
    return `${dayWeekDesc} ${hourDesc}`;
  };

  /**
   * in cron expressions, 0 or 7 are treated as Sunday. if 7
   * is present, we will remove the 7 and replace with a 0.
   * @param value days of the week as a number array
   */
  private modifyDaysOfWeek = (value: number[]) => {
    const days = new Set(value);
    if (days.has(7)) {
      days.delete(7);
      days.add(0);
    }

    // return the days as a sorted number array
    return [...days].sort() as DayOfWeekRange[];
  };

  private getHourDescription = (unixExpr: string) => {
    if (unixExpr === "*") {
      return "Every hour";
    }

    if (unixExpr.includes("/")) {
      const [_, rate] = unixExpr.split("/");
      const rateInt = parseInt(rate, 10);
      return rateInt === 1 ? "Every hour" : `every ${rateInt} hours`;
    }

    const hours = unixExpr.split(",");
    let firstOfKind = "";
    const tokens = hours.map(h => {
      if (h.includes("-")) {
        const [start, end] = h.split("-");
        const prefix = firstOfKind !== "between" ? "between " : "";
        firstOfKind = "between";
        return `${prefix}${this.toHourString(start)} & ${this.toHourString(end)}`;
      }

      const prefix = firstOfKind !== "at" ? "at " : "";
      firstOfKind = "at";
      return `${prefix}${this.toHourString(h)}`;
    });

    return joinListGrammatically(tokens);
  };

  private getDaysOfWeekDescription = (unixExpr: string) => {
    if (unixExpr === "*") {
      return "daily";
    }

    if (unixExpr.includes("/")) {
      const [_, rate] = unixExpr.split("/");
      const rateInt = parseInt(rate, 10);
      return rateInt === 1 ? "daily" : `every ${rateInt} days`;
    }

    const days = unixExpr.split(",");
    const tokens = days.map(h => {
      if (h.includes("-")) {
        const [start, end] = h.split("-");
        return `${this.toDayOfWeekString(start)} through ${this.toDayOfWeekString(end)}`;
      }
      return this.toDayOfWeekString(h);
    });

    return joinListGrammatically(tokens);
  };

  private toHourString = (value: number | string) => {
    const hour = typeof value === "string" ? parseInt(value, 10) : value;
    const res = hour % 12 || 12;
    const meridian = hour < 12 ? "am" : "pm";
    return `${res}:00 ${meridian}`;
  };

  private toDayOfWeekString = (value: number | string) => {
    const day = (typeof value === "string" ? parseInt(value, 10) : value) % 7;
    return titleCase(DAYS_OF_THE_WEEK[day]);
  };
}

BatterCron.prototype.toString = function () {
  return this.asExpression();
};

export {
  BatterCron,
  cn,
  compareValue,
  findParentNodeById,
  getOrdinal,
  isObjectEmpty,
  range,
  safeCompare,
  sleep,
  sortDays
};
