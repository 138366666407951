import { useQueries } from "@tanstack/react-query";
import { createContext, useEffect, useReducer } from "react";
import { getScheduleEditors, getScheduleStatuses, getScheduleTypes } from "@/api/schedule";
import { useAuth } from "@/hooks/useAuth";
import { Action, ReactContext, ReactProps } from "@/types/react";
import { Schedule, ScheduleStatus, ScheduleType } from "@/types/schedule";
import { ValueOpt } from "@/types/select";
import { toOptions } from "@/utils/form";
import { listToDict } from "@/utils/list";
import { titleCase } from "@/utils/string";

type ScheduleAction = Action & {
  schedules?: Schedule[];
  scheduleTypes?: ScheduleType[];
  scheduleEditors?: string[];
  scheduleStatuses?: ScheduleStatus[];
};

type ScheduleState = ReactContext<ScheduleAction> & {
  loading: boolean;
  schedules: Schedule[];
  scheduleTypes: ScheduleType[];
  scheduleEditors: string[];
  scheduleEditorOptions: ValueOpt<string>[];
  scheduleStatuses: ScheduleStatus[];
  scheduleStatusOptions: ValueOpt<string>[];
  scheduleTypesByCode: { [k: string]: ScheduleType };
  scheduleStatusesByText: { [k: string]: ScheduleStatus };
};

const initialState: ScheduleState = {
  dispatch: () => {},
  loading: false,
  schedules: [],
  scheduleTypes: [],
  scheduleEditors: [],
  scheduleEditorOptions: [],
  scheduleStatuses: [],
  scheduleStatusOptions: [],
  scheduleTypesByCode: {},
  scheduleStatusesByText: {}
};

const ScheduleContext = createContext(initialState);

const reducer = (state: ScheduleState, action: ScheduleAction): ScheduleState => {
  const { schedules = [], scheduleEditors = [], scheduleTypes = [], scheduleStatuses = [] } = action;
  switch (action.type) {
    case "setSchedules":
      return { ...state, schedules };
    case "setScheduleEditors":
      return {
        ...state,
        scheduleEditors,
        scheduleEditorOptions: toOptions(...scheduleEditors)
      };
    case "setScheduleTypes":
      return {
        ...state,
        scheduleTypes,
        scheduleTypesByCode: listToDict(scheduleTypes, s => s.code)
      };
    case "setScheduleStatuses":
      return {
        ...state,
        scheduleStatuses,
        scheduleStatusesByText: listToDict(scheduleStatuses, s => s.status),
        scheduleStatusOptions: scheduleStatuses.map(s => ({ label: titleCase(s.status), value: s.status }))
      };
    default:
      return state;
  }
};

const ScheduleProvider = ({ children }: ReactProps) => {
  // hooks
  const {
    loggedIn,
    roleInfo: { adminUser, broadcastUser, scheduleUser }
  } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  // initialize data
  const queries = useQueries({
    queries: [
      {
        enabled: loggedIn && (adminUser || broadcastUser || scheduleUser),
        queryKey: ["scheduleEditors"],
        queryFn: getScheduleEditors
      },
      {
        enabled: loggedIn && (adminUser || broadcastUser || scheduleUser),
        queryKey: ["scheduleTypes"],
        queryFn: getScheduleTypes
      },
      {
        enabled: loggedIn && (adminUser || broadcastUser || scheduleUser),
        queryKey: ["scheduleStatuses"],
        queryFn: getScheduleStatuses
      }
    ]
  });

  // constants
  const [{ data: scheduleEditors }, { data: scheduleTypes }, { data: scheduleStatuses }] = queries;
  const loading = queries.some(q => q.isLoading);

  // effects
  useEffect(() => {
    if (scheduleEditors) {
      dispatch({ type: "setScheduleEditors", scheduleEditors });
    }
  }, [scheduleEditors, dispatch]);

  useEffect(() => {
    if (scheduleTypes) {
      dispatch({ type: "setScheduleTypes", scheduleTypes });
    }
  }, [scheduleTypes, dispatch]);

  useEffect(() => {
    if (scheduleStatuses) {
      dispatch({ type: "setScheduleStatuses", scheduleStatuses });
    }
  }, [scheduleStatuses, dispatch]);

  return <ScheduleContext.Provider value={{ ...state, loading, dispatch }}>{children}</ScheduleContext.Provider>;
};

export { ScheduleContext, ScheduleProvider };
