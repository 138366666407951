import { GameConstants } from "@/constants/game";
import { SportGroup } from "@/types/game";
import { Team, TeamOption } from "@/types/team";

const getTeamName = (t: Team) => t.formattedName || t.fullTeamName || `${t.teamNameShort} ${t.teamName}`;

const getTeamSportGroup = (t: Team): SportGroup | null => {
  if (GameConstants.LeagueIds.WBC_IDS.has(t.leagueId || 0)) {
    return "WBC";
  }

  if (GameConstants.LeagueIds.FUTURES_IDS.has(t.leagueId || 0)) {
    return "Futures";
  }

  if (t.sportType === "MAJOR") {
    return "MLB";
  }

  if (t.sportType === "MINOR") {
    return "MiLB";
  }

  return null;
};

const teamOptionCreator = (t: Team): TeamOption => ({
  label: getTeamName(t),
  value: t.id,
  team: t
});

const createMlbTeamOptions = (teams: Team[]): TeamOption[] => {
  // divide teams by placeholders
  const activeTeams = teams.filter(t => !t.isPlaceholder);
  const placeholders = teams.filter(t => t.isPlaceholder);

  // create the MLB options
  const mlbTeamOptions = activeTeams.map(teamOptionCreator);
  const mlbPlaceHolderOptions = placeholders.map(teamOptionCreator);

  return [...mlbTeamOptions, ...mlbPlaceHolderOptions];
};

export { getTeamName, getTeamSportGroup, createMlbTeamOptions, teamOptionCreator };
