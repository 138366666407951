import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

type FadeProps = {
  in: boolean;
  children: React.ReactNode;
  duration?: number;
  unmountOnExit?: boolean;
  className?: string;
};

const Unmountable = styled.div<{ $duration: number }>`
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: ${({ $duration }) => `${$duration}ms`};
`;

const UnmountableFade = ({ children, duration = 300, in: active, ...props }: Omit<FadeProps, "unmountOnExit">) => {
  // hooks
  const timeout = useRef(0);
  const ref = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(active);

  // effects
  // when active, mount the component
  useEffect(() => {
    if (active) {
      setMounted(true);
    }
  }, [active]);

  // when inactive, unmount the component
  useEffect(() => {
    if (!active && mounted) {
      window.clearTimeout(timeout.current);
      if (ref.current) {
        ref.current.style.opacity = "";
      }
      timeout.current = window.setTimeout(() => {
        setMounted(false);
      }, duration);
    }
  }, [active, duration, mounted]);

  // when mounted, set opacity to 1
  useEffect(() => {
    if (mounted && ref.current) {
      // this line forces the browser to paint. if not, then we have to use a timeout
      ref.current.getBoundingClientRect();

      // set opacity to 1
      ref.current.style.opacity = "1";
    }
  }, [mounted]);

  // cleanup
  useEffect(() => {
    return () => {
      window.clearTimeout(timeout.current);
    };
  }, []);

  if (!mounted) return null;

  return (
    <Unmountable ref={ref} $duration={duration} {...props}>
      {children}
    </Unmountable>
  );
};

const StyledFade = styled.div<{ $duration: number; $active: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: ${({ $duration }) => `${$duration}ms`};
`;

const Fade = ({ children, in: active, duration = 300, unmountOnExit = false, ...props }: FadeProps) => {
  if (unmountOnExit)
    return (
      <UnmountableFade duration={duration} in={active} {...props}>
        {children}
      </UnmountableFade>
    );

  return (
    <StyledFade $active={active} $duration={duration} {...props}>
      {children}
    </StyledFade>
  );
};

export { Fade, type FadeProps };
