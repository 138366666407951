import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";

type ModalAction = Action & {
  type: "updateOpenCount";
  isOpen: boolean;
};

type ModalState = ReactContext<ModalAction> & {
  openCount: number;
};

const initialState: ModalState = {
  dispatch: () => {},
  openCount: 0
};

const ModalContext = createContext(initialState);

const reducer = (state: ModalState, action: ModalAction): ModalState => {
  const { type, isOpen } = action;
  // logic
  switch (type) {
    case "updateOpenCount": {
      return { ...state, openCount: isOpen ? state.openCount + 1 : Math.max(state.openCount - 1, 0) };
    }
    default:
      return state;
  }
};

const ModalProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ModalContext.Provider value={{ ...state, dispatch }}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalProvider };
