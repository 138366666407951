import { SportType } from "./game";
import { GroupOption, ValueOpt } from "./select";

export type LeagueOption = ValueOpt<number> & {
  league?: League;
};

export type GroupLeagueOption = GroupOption<number, LeagueOption>;

export type DivisionOption = ValueOpt<number> & {
  division?: LeagueDivision;
};

export type GroupDivisionOption = GroupOption<number, DivisionOption>;

export type League = {
  id: number;
  name: string;
  nameShort: string;
  abbreviation: string;
  orgCode: string;
  divisions: boolean;
  sportId: number;
  sportName: string;
  sportType?: SportType;
  sortOrder: number;
  active: boolean;
};

export type LeagueDivision = {
  id: number;
  name: string;
  shortName: string;
  leagueId: number;
  league: string;
  leagueCode: string;
  division: string;
  divisionCode: string;
  sortOrder: number;
};

export const getLeagueName = (l: League) => `${l.name} (${l.abbreviation})`;
