import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

// TODO: this will replace cssVariants. This stays here in order to create the different variant types
const _alertVariants = cva(
  "relative w-full rounded-lg border border-solid p-4 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.1)] [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        primary: "bg-[#cfe2ff] text-[#052c65] border-[#9ec5fe]",
        secondary: "bg-[#e2e3e5] text-[#2b2f32] border-[#c4c8cb]",
        success: "bg-[#d1e7dd] text-[#0a3622] border-[#a3cfbb]",
        danger: "bg-[#f8d7da] text-[#58151c] border-[#f1aeb5]",
        warning: "bg-[#fff3cd] text-[#664d03] border-[#ffe69c]",
        info: "bg-[#cff4fc] text-[#055160] border-[#9eeaf9]"
      }
    },
    defaultVariants: {
      variant: "primary"
    }
  }
);

type AlertVariant = Exclude<VariantProps<typeof _alertVariants>["variant"], undefined>;

/**
 * @deprecated
 * TODO: this will be removed once we add tailwind and remove react-bootstrap.
 * @param variant
 */
const cssVariants = (variant: AlertVariant = "primary") => {
  switch (variant) {
    case "secondary":
      return css`
        background-color: #e2e3e5;
        color: #2b2f32;
        border-color: #c4c8cb;
      `;
    case "success":
      return css`
        background-color: #d1e7dd;
        color: #0a3622;
        border-color: #a3cfbb;
      `;
    case "danger":
      return css`
        background-color: #f8d7da;
        color: #58151c;
        border-color: #f1aeb5;
      `;
    case "warning":
      return css`
        background-color: #fff3cd;
        color: #664d03;
        border-color: #ffe69c;
      `;
    case "info":
      return css`
        background-color: #cff4fc;
        color: #055160;
        border-color: #9eeaf9;
      `;
    default:
      return css`
        background-color: #cfe2ff;
        color: #052c65;
        border-color: #9ec5fe;
      `;
  }
};

const Alert = styled.div<{ $variant: AlertVariant }>`
  & > svg ~ * {
    padding-left: 1.75rem;
  }
  & > svg + div {
    transform: translateY(-3px);
  }
  & > svg {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid;
  padding: 1rem;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  ${props => cssVariants(props.$variant)};
`;

const Dismissable = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.875rem;
`;

type BatterAlertProps = React.ComponentPropsWithoutRef<"div"> & {
  variant?: AlertVariant;
  dismissable?: boolean;
  onRemove?: () => void;
};

const BatterAlert = forwardRef<HTMLDivElement, BatterAlertProps>(
  ({ variant = "primary", children, dismissable = false, onRemove, ...props }, ref) => (
    <Alert ref={ref} role="alert" $variant={variant} {...props}>
      {children}
      {dismissable && (
        <Dismissable>
          <FontAwesomeIcon icon={faTimesCircle} onClick={onRemove} style={{ cursor: "pointer" }} />
        </Dismissable>
      )}
    </Alert>
  )
);

BatterAlert.displayName = "BatterAlert";

export { BatterAlert, type BatterAlertProps, type AlertVariant };
