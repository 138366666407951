import { LocalStorageConstants } from "../constants/user";
import { BatterUserInfo } from "../types/core";

const getLocalStorageUserInfo = (): { userName: string; roles: string[] } => {
  const roles = localStorage.getItem(LocalStorageConstants.BATTER_ROLES);
  return {
    userName: localStorage.getItem(LocalStorageConstants.BATTER_USERNAME) || "",
    roles: roles ? JSON.parse(roles) : undefined
  };
};

const setLocalStorageUserInfo = (userInfo: BatterUserInfo) => {
  localStorage.setItem(LocalStorageConstants.BATTER_USERNAME, userInfo.username);
  localStorage.setItem(LocalStorageConstants.BATTER_ROLES, JSON.stringify(userInfo.roles));
};

export { getLocalStorageUserInfo, setLocalStorageUserInfo };
