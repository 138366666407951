import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GlobalAlert from "@/components/GlobalAlert";
import { AlertProvider } from "@/contexts/alert";
import { AuthProvider } from "@/contexts/auth";
import { BroadcastProvider } from "@/contexts/broadcast";
import { DocumentProvider } from "@/contexts/document";
import { EventProvider } from "@/contexts/event";
import { GameProvider } from "@/contexts/game";
import { IgspProvider } from "@/contexts/igsp";
import { LayoutProvider } from "@/contexts/layout";
import { LoadingProvider } from "@/contexts/loading";
import { LookupProvider } from "@/contexts/lookup";
import { MemoProvider } from "@/contexts/memo";
import { ModalProvider } from "@/contexts/modal";
import { ReportProvider } from "@/contexts/report";
import { ScheduleProvider } from "@/contexts/schedule";
import { StatsApiProvider } from "@/contexts/statsApi";
import { TemplateProvider } from "@/contexts/template";
import { ReactProps } from "@/types/react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const WrapperProvider = ({ children }: ReactProps) => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <LayoutProvider>
        <ModalProvider>
          <AlertProvider>
            <LoadingProvider>
              <DocumentProvider>
                <ScheduleProvider>
                  <BroadcastProvider>
                    <IgspProvider>
                      <ReportProvider>
                        <EventProvider>
                          <LookupProvider>
                            <GameProvider>
                              <MemoProvider>
                                <TemplateProvider>
                                  <StatsApiProvider>
                                    {children}
                                    <GlobalAlert />
                                  </StatsApiProvider>
                                </TemplateProvider>
                              </MemoProvider>
                            </GameProvider>
                          </LookupProvider>
                        </EventProvider>
                      </ReportProvider>
                    </IgspProvider>
                  </BroadcastProvider>
                </ScheduleProvider>
              </DocumentProvider>
            </LoadingProvider>
          </AlertProvider>
        </ModalProvider>
      </LayoutProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default WrapperProvider;
