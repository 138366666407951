import { AxiosResponse } from "axios";
import { BatterDate, BatterDateTime, BatterTime, formatDate, formatDateTime, formatTime } from "./date";
import { isBlank } from "./string";

const convertValue = (value: object): string => {
  // handle dates & times
  if (value instanceof BatterDate) {
    return formatDate(value, "yyyy-MM-dd");
  }

  if (value instanceof BatterTime) {
    return formatTime(value, "HH:mm");
  }

  if (value instanceof BatterDateTime) {
    return formatDateTime(value, "yyyy-MM-dd'T'HH:mm:ssZZZ");
  }

  // handle arrays
  if (Array.isArray(value)) {
    return value.map(convertValue).join(",");
  }

  // for anything else, just return the toString
  return value.toString();
};

/**
 * creates a query param string from an object
 * @param params params object
 * @returns string
 */
const generateQueryParams = <T extends object>(params: T) => {
  const res = Object.entries(params || {})
    .filter(([_, value]) => {
      return (
        value != null && (typeof value !== "string" || !isBlank(value)) && (!Array.isArray(value) || !!value.length)
      );
    })
    .map(([key, value]) => `${key}=${encodeURIComponent(convertValue(value))}`)
    .join("&");
  return res ? `?${res}` : "";
};

/**
 * opens the file dialog to download a Blob
 * @param res AxiosResponse
 * @param nameOfFile an optional name of the file if the header is not present
 */
const downloadBlob = <E>(res: AxiosResponse<Blob, E>, nameOfFile?: string) => {
  // get fileName
  const disp = res.headers["content-disposition"] || "";
  const index = disp.indexOf("=");
  const fileName = index === -1 ? nameOfFile : disp.slice(index + 1);

  // get blob object
  const url = window.URL.createObjectURL(res.data);
  const ref = window.document.createElement("a");
  ref.href = url;
  ref.download = fileName;
  window.document.body.appendChild(ref);
  ref.click();

  // clear memory after a timeout
  window.setTimeout(() => {
    ref.remove();
    window.URL.revokeObjectURL(url);
  }, 0);
};

const toQueryParamDict = <T extends object>(arg: T) => {
  return Object.entries(arg)
    .filter(([_, value]) => {
      return (
        value != null && (typeof value !== "string" || !isBlank(value)) && (!Array.isArray(value) || !!value.length)
      );
    })
    .reduce(
      (pv, [key, value]) => ({
        ...pv,
        [key]: convertValue(value)
      }),
      {} as Record<string, string>
    );
};

const parseNumberQueryParam = (params: URLSearchParams, key: string) => {
  const value = Number(params.get(key));
  return isNaN(value) ? null : value;
};

export { downloadBlob, generateQueryParams, parseNumberQueryParam, toQueryParamDict };
