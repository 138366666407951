import { GameFilters } from "@/types/game";
import * as igsp from "@/types/igsp";
import { ValueOpt } from "@/types/select";
import { generateQueryParams } from "@/utils/http";
import { batterApi } from "./batter";

const getIgspClientBroadcasters = async (active?: boolean) => {
  const result = await batterApi.get<{ broadcasters: igsp.IgspClientBroadcaster[] }>(
    `/v2/igsp/clientBroadcasters${generateQueryParams({ active })}`
  );
  return result.data.broadcasters;
};

const getIgspClientBroadcasterOptions = async (active?: boolean) => {
  const broadcasters = await getIgspClientBroadcasters(active);
  return broadcasters.filter(b => b.active).map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspClientBroadcaster = async (payload: igsp.SaveIgspClientBroadcaster, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspClientBroadcaster>(`/v2/igsp/clientBroadcasters/${id}`, payload)
    : await batterApi.post<igsp.IgspClientBroadcaster>("/v2/igsp/clientBroadcasters", payload);
  return result.data;
};

const deleteIgspClientBroadcaster = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspClientBroadcaster>(`/v2/igsp/clientBroadcasters/${id}`);
  return result.data;
};

const saveIgspContract = async (payload: igsp.SaveIgspContract, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspContract>(`/v2/igsp/contracts/${id}`, payload)
    : await batterApi.post<igsp.IgspContract>("/v2/igsp/contracts", payload);
  return result.data;
};

const getIgspCountries = async (active?: boolean) => {
  const result = await batterApi.get<{ countries: igsp.IgspCountry[] }>(
    `/v2/igsp/countries${generateQueryParams({ active })}`
  );
  return result.data.countries;
};

const getIgspCountryOptions = async (active?: boolean) => {
  const countries = await getIgspCountries(active);
  return countries.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspCountry = async (payload: igsp.SaveIgspCountry, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspCountry>(`/v2/igsp/countries/${id}`, payload)
    : await batterApi.post<igsp.IgspCountry>("/v2/igsp/countries", payload);
  return result.data;
};

const deleteIgspCountry = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspCountry>(`/v2/igsp/countries/${id}`);
  return result.data;
};

const getIgspDecoderBrands = async (active?: boolean) => {
  const result = await batterApi.get<{ brands: igsp.IgspDecoderBrand[] }>(
    `/v2/igsp/decoder/brands${generateQueryParams({ active })}`
  );
  return result.data.brands;
};

const getIgspDecoderBrandOptions = async (active?: boolean) => {
  const brands = await getIgspDecoderBrands(active);
  return brands.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspDecoderBrand = async (payload: igsp.SaveIgspDecoderBrand, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspDecoderBrand>(`/v2/igsp/decoder/brands/${id}`, payload)
    : await batterApi.post<igsp.IgspDecoderBrand>("/v2/igsp/decoder/brands", payload);
  return result.data;
};

const deleteIgspDecoderBrand = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspDecoderBrand>(`/v2/igsp/decoder/brands/${id}`);
  return result.data;
};

const getIgspDecoderModels = async (active?: boolean) => {
  const result = await batterApi.get<{ models: igsp.IgspDecoderModel[] }>(
    `/v2/igsp/decoder/models${generateQueryParams({ active })}`
  );
  return result.data.models;
};

const getIgspDecoderModelOptions = async (active?: boolean) => {
  const models = await getIgspDecoderModels(active);
  return models.map((f): ValueOpt<number> => ({ label: `${f.brand.name} ${f.name}`, value: f.id }));
};

const saveIgspDecoderModel = async (payload: igsp.SaveIgspDecoderModel, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspDecoderModel>(`/v2/igsp/decoder/models/${id}`, payload)
    : await batterApi.post<igsp.IgspDecoderModel>("/v2/igsp/decoder/models", payload);
  return result.data;
};

const deleteIgspDecoderModel = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspDecoderModel>(`/v2/igsp/decoder/models/${id}`);
  return result.data;
};

const getIgspFeeds = async (active?: boolean) => {
  const result = await batterApi.get<{ feeds: igsp.IgspFeed[] }>(`/v2/igsp/feeds${generateQueryParams({ active })}`);
  return result.data.feeds;
};

const getIgspFeedOptions = async (active?: boolean) => {
  const feeds = await getIgspFeeds(active);
  return feeds.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspFeed = async (payload: igsp.SaveIgspFeed, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspFeed>(`/v2/igsp/feeds/${id}`, payload)
    : await batterApi.post<igsp.IgspFeed>("/v2/igsp/feeds", payload);
  return result.data;
};

const deleteIgspFeed = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspFeed>(`/v2/igsp/feeds/${id}`);
  return result.data;
};

const getIgspJewelEvents = async (active?: boolean) => {
  const result = await batterApi.get<{ events: igsp.IgspJewelEvent[] }>(
    `/v2/igsp/events${generateQueryParams({ active })}`
  );
  return result.data.events;
};

const getIgspJewelEventOptions = async (active?: boolean) => {
  const events = await getIgspJewelEvents(active);
  return events.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspJewelEvent = async (payload: igsp.SaveIgspJewelEvent, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspJewelEvent>(`/v2/igsp/events/${id}`, payload)
    : await batterApi.post<igsp.IgspJewelEvent>("/v2/igsp/events", payload);
  return result.data;
};

const deleteIgspJewelEvent = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspJewelEvent>(`/v2/igsp/events/${id}`);
  return result.data;
};

const getIgspNetworks = async (filters: igsp.IgspNetworkFilters = {}) => {
  const result = await batterApi.get<{ networks: igsp.IgspNetwork[] }>(
    `/v2/igsp/networks${generateQueryParams(filters)}`
  );
  return result.data.networks;
};

const getIgspNetworkOptions = async (filters: igsp.IgspNetworkFilters = {}) => {
  const networks = await getIgspNetworks(filters);
  return networks.map((f): ValueOpt<number> => ({ label: f.source.description, value: f.id }));
};

const getIgspNetworkContactsLabels = async () => {
  const result = await batterApi.get<{ labels: igsp.IgspNetworkContactLabel[] }>("/v2/igsp/contactLabels");
  return result.data.labels;
};

const saveIgspNetwork = async (payload: igsp.SaveIgspNetwork, id: number) => {
  const result = await batterApi.put<igsp.IgspNetwork>(`/v2/igsp/networks/${id}`, payload);
  return result.data;
};

const getIgspRegions = async (active?: boolean) => {
  const result = await batterApi.get<{ regions: igsp.IgspRegion[] }>(
    `/v2/igsp/regions${generateQueryParams({ active })}`
  );
  return result.data.regions;
};

const getIgspRegionOptions = async (active?: boolean) => {
  const regions = await getIgspRegions(active);
  return regions.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspRegion = async (payload: igsp.SaveIgspRegion, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspRegion>(`/v2/igsp/regions/${id}`, payload)
    : await batterApi.post<igsp.IgspRegion>("/v2/igsp/regions", payload);
  return result.data;
};

const deleteIgspRegion = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspRegion>(`/v2/igsp/regions/${id}`);
  return result.data;
};

const getIgspShows = async (active?: boolean) => {
  const result = await batterApi.get<{ shows: igsp.IgspShow[] }>(`/v2/igsp/shows${generateQueryParams({ active })}`);
  return result.data.shows;
};

const getIgspShowOptions = async (active?: boolean) => {
  const shows = await getIgspShows(active);
  return shows.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspShow = async (payload: igsp.SaveIgspShow, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspShow>(`/v2/igsp/shows/${id}`, payload)
    : await batterApi.post<igsp.IgspShow>("/v2/igsp/shows", payload);
  return result.data;
};

const deleteIgspShow = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspShow>(`/v2/igsp/shows/${id}`);
  return result.data;
};

const getIgspSignagePaths = async (active?: boolean) => {
  const result = await batterApi.get<{ signagePaths: igsp.IgspSignagePath[] }>(
    `/v2/igsp/signagePaths${generateQueryParams({ active })}`
  );
  return result.data.signagePaths;
};

const getIgspSignagePathOptions = async (active?: boolean) => {
  const paths = await getIgspSignagePaths(active);
  return paths.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspSignagePath = async (payload: igsp.SaveIgspSignagePath, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspSignagePath>(`/v2/igsp/signagePaths/${id}`, payload)
    : await batterApi.post<igsp.IgspSignagePath>("/v2/igsp/signagePaths", payload);
  return result.data;
};

const deleteIgspSignagePath = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspSignagePath>(`/v2/igsp/signagePaths/${id}`);
  return result.data;
};

const getIgspTransmissionMethods = async (active?: boolean) => {
  const result = await batterApi.get<{ methods: igsp.IgspTransmissionMethod[] }>(
    `/v2/igsp/transmission/methods${generateQueryParams({ active })}`
  );
  return result.data.methods;
};

const getIgspTransmissionMethodOptions = async (active?: boolean) => {
  const formats = await getIgspTransmissionMethods(active);
  return formats.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspTransmissionMethod = async (payload: igsp.SaveIgspTransmissionMethod, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspTransmissionMethod>(`/v2/igsp/transmission/methods/${id}`, payload)
    : await batterApi.post<igsp.IgspTransmissionMethod>("/v2/igsp/transmission/methods", payload);
  return result.data;
};

const deleteIgspTransmissionMethod = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspTransmissionMethod>(`/v2/igsp/transmission/methods/${id}`);
  return result.data;
};

const getIgspTransmits = async (active?: boolean) => {
  const result = await batterApi.get<{ transmits: igsp.IgspTransmit[] }>(
    `/v2/igsp/transmits${generateQueryParams({ active })}`
  );
  return result.data.transmits;
};

const saveIgspTransmit = async (payload: igsp.SaveIgspTransmit, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspTransmit>(`/v2/igsp/transmits/${id}`, payload)
    : await batterApi.post<igsp.IgspTransmit>("/v2/igsp/transmits", payload);
  return result.data;
};

const deleteIgspTransmit = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspTransmit>(`/v2/igsp/transmits/${id}`);
  return result.data;
};

const getIgspTransmitDestinations = async (active?: boolean) => {
  const result = await batterApi.get<{ destinations: igsp.IgspTransmitDestination[] }>(
    `/v2/igsp/transmit/destinations${generateQueryParams({ active })}`
  );
  return result.data.destinations;
};

const getIgspTransmitDestinationOptions = async (active?: boolean) => {
  const formats = await getIgspTransmitDestinations(active);
  return formats.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspTransmitDestination = async (payload: igsp.SaveIgspTransmitDestination, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspTransmitDestination>(`/v2/igsp/transmit/destinations/${id}`, payload)
    : await batterApi.post<igsp.IgspTransmitDestination>("/v2/igsp/transmit/destinations", payload);
  return result.data;
};

const deleteIgspTransmitDestination = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspTransmitDestination>(`/v2/igsp/transmit/destinations/${id}`);
  return result.data;
};

const getIgspTransmitFormats = async (active?: boolean) => {
  const result = await batterApi.get<{ formats: igsp.IgspTransmitFormat[] }>(
    `/v2/igsp/transmit/formats${generateQueryParams({ active })}`
  );
  return result.data.formats;
};

const getIgspTransmitFormatOptions = async (active?: boolean) => {
  const formats = await getIgspTransmitFormats(active);
  return formats.map((f): ValueOpt<number> => ({ label: f.name, value: f.id }));
};

const saveIgspTransmitFormat = async (payload: igsp.SaveIgspTransmitFormat, id?: number) => {
  const result = id
    ? await batterApi.put<igsp.IgspTransmitFormat>(`/v2/igsp/transmit/formats/${id}`, payload)
    : await batterApi.post<igsp.IgspTransmitFormat>("/v2/igsp/transmit/formats", payload);
  return result.data;
};

const deleteIgspTransmitFormat = async (id: number) => {
  const result = await batterApi.delete<igsp.IgspTransmitFormat>(`/v2/igsp/transmit/formats/${id}`);
  return result.data;
};

const getIgspRequests = async (filters: igsp.IgspRequestFilters) => {
  const results = await batterApi.get<{ requests: igsp.IgspRequest[] }>(
    `/v2/igsp/requests${generateQueryParams(filters)}`
  );
  return results.data.requests;
};

const createIgspRequest = async (payload: igsp.CreateIgspRequest) => {
  const results = await batterApi.post<igsp.IgspRequest>("/v2/igsp/requests", payload);
  return results.data;
};

const approveIgspRequest = async (id: number, payload: igsp.ApproveIgspRequest) => {
  const results = await batterApi.patch<igsp.IgspRequest>(`/v2/igsp/requests/${id}/approve`, payload);
  return results.data;
};

const bulkApproveIgspRequests = async (requestIds: number[], payload: igsp.ApproveIgspRequest = {}) => {
  const results = await batterApi.patch<{ requests: igsp.IgspRequest }>(
    `/v2/igsp/requests/approve${generateQueryParams({ requestIds })}`,
    payload
  );
  return results.data.requests;
};

const denyIgspRequest = async (id: number, payload: igsp.DenyIgspRequest) => {
  const results = await batterApi.patch<igsp.IgspRequest>(`/v2/igsp/requests/${id}/deny`, payload);
  return results.data;
};

const bulkDenyIgspRequests = async (requestIds: number[], payload: igsp.DenyIgspRequest) => {
  const results = await batterApi.patch<{ requests: igsp.IgspRequest }>(
    `/v2/igsp/requests/deny${generateQueryParams({ requestIds })}`,
    payload
  );
  return results.data.requests;
};

const cancelIgspRequest = async (id: number) => {
  const results = await batterApi.patch<igsp.IgspRequest>(`/v2/igsp/requests/${id}/cancel`);
  return results.data;
};

const finalizeIgspRequest = async (id: number, payload?: Partial<igsp.FinalizeIgspRequest>) => {
  const results = await batterApi.patch<igsp.IgspRequest>(`/v2/igsp/requests/${id}/finalize`, payload);
  return results.data;
};

const saveIgspRequest = async (id: number, payload: igsp.SaveIgspRequest) => {
  const results = await batterApi.patch<igsp.IgspRequest>(`/v2/igsp/requests/${id}`, payload);
  return results.data;
};

const getIgspViolationsForGame = async (batterPk: number) => {
  const results = await batterApi.get<igsp.IgspContractRuleViolations>(`/v2/igsp/games/${batterPk}/violations`);
  return results.data;
};

const getIgspViolationsForRequest = async (requestId: number) => {
  const results = await batterApi.get<igsp.IgspContractRuleViolations>(`/v2/igsp/requests/${requestId}/violations`);
  return results.data;
};

const getIgspBroadcastEvents = async (filters: GameFilters) => {
  const results = await batterApi.get<{ events: igsp.IgspBroadcastEvent[] }>(
    `/v2/igsp/broadcastEvents${generateQueryParams(filters)}`
  );
  return results.data.events;
};

const getIgspGameFeedNetworks = async (batterPk: number, clientBroadcasterId?: number) => {
  const results = await batterApi.get<igsp.IgspGameFeedNetworks>(
    `/v2/igsp/games/${batterPk}/networks${generateQueryParams({ clientBroadcasterId })}`
  );
  return results.data;
};

const getIgspDocuments = async (filters: igsp.IgspDocumentFilters, signal?: AbortSignal) => {
  const results = await batterApi.get<{ documents: igsp.IgspDocument[] }>(
    `/v2/igsp/documents${generateQueryParams(filters)}`,
    { signal }
  );
  return results.data.documents;
};

const getIgspDocumentYears = async () => {
  const results = await batterApi.get<{ years: number[] }>("/v2/igsp/documents/years");
  return results.data.years;
};

const uploadIgspDocument = async ({ file, teamIds, networkIds, gameTypes }: igsp.UploadIgspDocument) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("teamIds", teamIds.join(","));
  formData.append("networkIds", networkIds.join(","));
  formData.append("gameTypes", gameTypes.join(","));

  const response = await batterApi.post<igsp.IgspDocument>("/v2/igsp/documents", formData);
  return response.data;
};

const deleteIgspDocument = async (id: number) => {
  const response = await batterApi.delete<igsp.IgspDocument>(`/v2/igsp/documents/${id}`);
  return response.data;
};

const downloadIgspDocument = async (documentId: number) => {
  const response = await batterApi.get<{ url: string }>(`/v2/igsp/documents/${documentId}/download`);
  return response.data.url;
};

const tagIgspDocument = async (id: number, payload: igsp.TagIgspDocument) => {
  const response = await batterApi.post<igsp.IgspDocument>(`/v2/igsp/documents/${id}/tags`, payload);
  return response.data;
};

const getIgspInfo = async () => {
  const response = await batterApi.get<igsp.IgspInfo>("/v2/igsp/info");
  return response.data;
};

const saveIgspInfo = async (payload: igsp.SaveIgspInfo) => {
  const response = await batterApi.post<igsp.IgspInfo>("/v2/igsp/info", payload);
  return response.data;
};

const getIgspInfoImages = async () => {
  const response = await batterApi.get<{ images: igsp.IgspInfoImage[] }>("/v2/igsp/info/images");
  return response.data.images;
};

const uploadIgspInfoImage = async (payload: igsp.UploadIgspInfoImage) => {
  const formData = new FormData();
  formData.append("file", payload.file, payload.file.name);
  formData.append("altText", payload.altText);

  const response = await batterApi.post<igsp.IgspInfoImage>("/v2/igsp/info/images", formData);
  return response.data;
};

export {
  approveIgspRequest,
  bulkApproveIgspRequests,
  bulkDenyIgspRequests,
  cancelIgspRequest,
  createIgspRequest,
  deleteIgspClientBroadcaster,
  deleteIgspCountry,
  deleteIgspDecoderBrand,
  deleteIgspDecoderModel,
  deleteIgspDocument,
  deleteIgspFeed,
  deleteIgspJewelEvent,
  deleteIgspRegion,
  deleteIgspShow,
  deleteIgspSignagePath,
  deleteIgspTransmissionMethod,
  deleteIgspTransmit,
  deleteIgspTransmitDestination,
  deleteIgspTransmitFormat,
  denyIgspRequest,
  downloadIgspDocument,
  finalizeIgspRequest,
  getIgspBroadcastEvents,
  getIgspClientBroadcasterOptions,
  getIgspClientBroadcasters,
  getIgspCountries,
  getIgspCountryOptions,
  getIgspDecoderBrandOptions,
  getIgspDecoderBrands,
  getIgspDecoderModelOptions,
  getIgspDecoderModels,
  getIgspDocuments,
  getIgspDocumentYears,
  getIgspFeedOptions,
  getIgspFeeds,
  getIgspGameFeedNetworks,
  getIgspInfo,
  getIgspInfoImages,
  getIgspJewelEventOptions,
  getIgspJewelEvents,
  getIgspNetworkContactsLabels,
  getIgspNetworkOptions,
  getIgspNetworks,
  getIgspRegionOptions,
  getIgspRegions,
  getIgspRequests,
  getIgspShowOptions,
  getIgspShows,
  getIgspSignagePathOptions,
  getIgspSignagePaths,
  getIgspTransmissionMethodOptions,
  getIgspTransmissionMethods,
  getIgspTransmitDestinationOptions,
  getIgspTransmitDestinations,
  getIgspTransmitFormatOptions,
  getIgspTransmitFormats,
  getIgspTransmits,
  getIgspViolationsForGame,
  getIgspViolationsForRequest,
  saveIgspClientBroadcaster,
  saveIgspContract,
  saveIgspCountry,
  saveIgspDecoderBrand,
  saveIgspDecoderModel,
  saveIgspFeed,
  saveIgspInfo,
  saveIgspJewelEvent,
  saveIgspNetwork,
  saveIgspRegion,
  saveIgspRequest,
  saveIgspShow,
  saveIgspSignagePath,
  saveIgspTransmissionMethod,
  saveIgspTransmit,
  saveIgspTransmitDestination,
  saveIgspTransmitFormat,
  tagIgspDocument,
  uploadIgspDocument,
  uploadIgspInfoImage
};
