import { DomesticRule } from "@/types/broadcast";
import { BatterUserInfo } from "@/types/core";
import { IgspMember } from "@/types/igsp";
import {
  BroadcastUserMapping,
  IgspClientUserMapping,
  TeamUserMapping,
  UpdateBroadcastMappingPayload,
  UpdateIgspClientMapping,
  UpdateTeamMappingPayload
} from "@/types/mappings";
import { batterApi } from "./batter";

const getUserInfo = async () => {
  return (await batterApi.get<BatterUserInfo>("/v2/users")).data;
};

const getTeamUserMappings = async () => {
  return (await batterApi.get<{ mappings: TeamUserMapping[] }>("/v2/users/mappings")).data.mappings;
};

const updateTeamMappings = async (payload: UpdateTeamMappingPayload) => {
  const results = await batterApi.post<{ mappings: TeamUserMapping[] }>("/v2/users/mappings", payload);
  return results.data.mappings;
};

const getBroadcastUserMappings = async () => {
  return (await batterApi.get<{ mappings: BroadcastUserMapping[] }>("/v2/users/broadcastMappings")).data.mappings;
};

const updateBroadcastMappings = async (payload: UpdateBroadcastMappingPayload) => {
  const results = await batterApi.post<{ mappings: BroadcastUserMapping[] }>("/v2/users/broadcastMappings", payload);
  return results.data.mappings;
};

const getIgspClientUserMappings = async () => {
  return (await batterApi.get<{ mappings: IgspClientUserMapping[] }>("/v2/users/igspClientMappings")).data.mappings;
};

const updateIgspClientMappings = async (mappings: UpdateIgspClientMapping[]) => {
  const results = await batterApi.post<{ mappings: IgspClientUserMapping[] }>("/v2/users/igspClientMappings", {
    mappings
  });
  return results.data.mappings;
};

const getUserDomesticRules = async () => {
  return (await batterApi.get<{ rules: DomesticRule[] }>("/v2/users/domesticRules")).data.rules;
};

const getIgspUsersForClientBroadcaster = async (id: number) => {
  return (await batterApi.get<{ members: IgspMember[] }>(`/v2/igsp/clientBroadcasters/${id}/members`)).data.members;
};

export {
  getBroadcastUserMappings,
  getIgspClientUserMappings,
  getTeamUserMappings,
  getUserDomesticRules,
  getUserInfo,
  getIgspUsersForClientBroadcaster,
  updateBroadcastMappings,
  updateIgspClientMappings,
  updateTeamMappings
};
