import { createContext, useReducer } from "react";
import {
  BroadcastMethod,
  BroadcastRequest,
  BroadcastSource,
  BroadcastSourceBucket,
  BroadcastType,
  DomesticRule,
  Language,
  NationalQualifier,
  SapLanguage
} from "@/types/broadcast";
import { Action, ReactContext, ReactProps } from "@/types/react";
import { ValueOpt } from "@/types/select";
import { compareValue } from "@/utils/core";
import { broadcastSourceOptionCreator } from "@/utils/form";
import { listToDict, upsert } from "@/utils/list";

type BroadcastAction = Action & {
  broadcastTypes?: BroadcastType[];
  broadcastMethods?: BroadcastMethod[];
  broadcastSources?: BroadcastSource[];
  domesticRules?: DomesticRule[];
  broadcastSourceBuckets?: BroadcastSourceBucket[];
  nationalQualifiers?: NationalQualifier[];
  languages?: Language[];
  sapLanguages?: SapLanguage[];
  broadcastRequest?: BroadcastRequest;
  broadcastRequests?: BroadcastRequest[];
};

type BroadcastState = ReactContext<BroadcastAction> & {
  broadcastTypes: BroadcastType[];
  broadcastMethods: BroadcastMethod[];
  broadcastSources: BroadcastSource[];
  domesticRules: DomesticRule[];
  broadcastSourceBuckets: BroadcastSourceBucket[];
  broadcastRequests: BroadcastRequest[];
  sourceById: { [x: number]: BroadcastSource };
  sourceBucketsById: { [x: number]: BroadcastSourceBucket };
  domesticRulesBySourceId: { [x: number]: { [slug: string]: DomesticRule } };
  nationalQualifiers: NationalQualifier[];
  languages: Language[];
  languageByName: { [x: string]: Language };
  sapLanguages: SapLanguage[];
  sapLanguageByCode: { [x: string]: Language };
  broadcastTypeOptions: ValueOpt<number>[];
  broadcastSourceOptions: ValueOpt<number>[];
  broadcastMethodOptions: ValueOpt<number>[];
  broadcastSourceBucketOptions: ValueOpt<number>[];
  languageOptions: ValueOpt<number>[];
  sapLanguageOptions: ValueOpt<number>[];
  nationalQualifierById: { [x: number]: NationalQualifier };
  nationalQualifierOptions: ValueOpt<number>[];
  activeSourceOptions: ValueOpt<number>[];
  activeNationalSourceOptions: ValueOpt<number>[];
  activeNationalTvSourceOptions: ValueOpt<number>[];
  tvSourceOptions: ValueOpt<number>[];
  outOfMarketSourceOptions: ValueOpt<number>[];
};

const initialState: BroadcastState = {
  dispatch: () => {},
  broadcastTypes: [],
  broadcastMethods: [],
  broadcastSources: [],
  domesticRules: [],
  broadcastSourceBuckets: [],
  broadcastRequests: [],
  sourceById: {},
  sourceBucketsById: {},
  domesticRulesBySourceId: {},
  nationalQualifiers: [],
  languages: [],
  languageByName: {},
  sapLanguages: [],
  sapLanguageByCode: {},
  broadcastTypeOptions: [],
  broadcastSourceOptions: [],
  broadcastMethodOptions: [],
  broadcastSourceBucketOptions: [],
  languageOptions: [],
  sapLanguageOptions: [],
  nationalQualifierById: {},
  nationalQualifierOptions: [],
  activeSourceOptions: [],
  activeNationalSourceOptions: [],
  activeNationalTvSourceOptions: [],
  tvSourceOptions: [],
  outOfMarketSourceOptions: []
};

const BroadcastContext = createContext(initialState);

const createSourceOptions = (sources: BroadcastSource[]): Partial<BroadcastState> => {
  const activeSources = sources.filter(s => s.isActive);
  const activeNationalSources = activeSources.filter(s => s.broadcastRegion === "National");
  return {
    broadcastSources: sources,
    sourceById: listToDict(sources, s => s.id),
    broadcastSourceOptions: sources.map(broadcastSourceOptionCreator),
    activeSourceOptions: activeSources.map(broadcastSourceOptionCreator),
    activeNationalSourceOptions: activeNationalSources.map(broadcastSourceOptionCreator),
    activeNationalTvSourceOptions: activeNationalSources.filter(s => s.type === "TV").map(broadcastSourceOptionCreator),
    tvSourceOptions: sources.filter(s => s.type === "TV").map(broadcastSourceOptionCreator),
    outOfMarketSourceOptions: sources.filter(s => s.isOutOfMarket).map(broadcastSourceOptionCreator)
  };
};

const mapBroadcastSourceIdsToDomesticRules = (rules: DomesticRule[]) => {
  const results: { [x: number]: { [slug: string]: DomesticRule } } = {};
  rules.forEach(r => {
    r.mappings?.forEach(mapping => {
      const rules = results[mapping.sourceId] || {};
      results[mapping.sourceId] = {
        ...rules,
        [r.slug]: {
          ...r,
          active: mapping.active || r.active,
          validFrom: mapping.validFrom || r.validFrom,
          validUntil: mapping.validUntil || r.validUntil
        }
      };
    });
  });
  return results;
};

const reducer = (state: BroadcastState, action: BroadcastAction): BroadcastState => {
  const {
    broadcastSources = [],
    broadcastTypes = [],
    broadcastMethods = [],
    broadcastSourceBuckets = [],
    nationalQualifiers = [],
    languages = [],
    sapLanguages = [],
    broadcastRequests = [],
    domesticRules = []
  } = action;

  switch (action.type) {
    case "setBroadcastTypes":
      return {
        ...state,
        broadcastTypes,
        broadcastTypeOptions: broadcastTypes.map(t => ({ value: t.id, label: t.type }))
      };
    case "setBroadcastMethods":
      return {
        ...state,
        broadcastMethods,
        broadcastMethodOptions: broadcastMethods.map(m => ({ value: m.id, label: m.method }))
      };
    case "setBroadcastSources":
      return {
        ...state,
        ...createSourceOptions(broadcastSources)
      };
    case "setBroadcastSourceBuckets":
      return {
        ...state,
        broadcastSourceBuckets,
        sourceBucketsById: listToDict(broadcastSourceBuckets, s => s.id),
        broadcastSourceBucketOptions: broadcastSourceBuckets.map(m => ({ value: m.id, label: m.name }))
      };
    case "setDomesticRules":
      return {
        ...state,
        domesticRules,
        domesticRulesBySourceId: mapBroadcastSourceIdsToDomesticRules(domesticRules)
      };
    case "setLanguages":
      return {
        ...state,
        languages,
        languageOptions: languages.map(l => ({ value: l.id, label: l.language })),
        languageByName: listToDict(languages, l => l.language)
      };
    case "setSapLanguages":
      return {
        ...state,
        sapLanguages,
        sapLanguageOptions: sapLanguages.map(l => ({ value: l.id, label: l.language })),
        sapLanguageByCode: listToDict(sapLanguages, l => l.initial)
      };
    case "setNationalQualifiers":
      return {
        ...state,
        nationalQualifiers,
        nationalQualifierById: nationalQualifiers.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        nationalQualifierOptions: nationalQualifiers.map(q => ({ value: q.id, label: q.description }))
      };
    case "setBroadcastMetaData": {
      return {
        ...state,
        ...createSourceOptions(broadcastSources),
        broadcastTypes,
        broadcastMethods,
        broadcastSourceBuckets,
        nationalQualifiers,
        languages,
        sapLanguages,
        broadcastTypeOptions: broadcastTypes.map(t => ({ value: t.id, label: t.type })),
        broadcastMethodOptions: broadcastMethods.map(m => ({ value: m.id, label: m.method })),
        broadcastSourceBucketOptions: broadcastSourceBuckets.map(m => ({
          value: m.id,
          label: m.name
        })),
        languageOptions: languages.map(l => ({ value: l.id, label: l.language })),
        sapLanguageOptions: sapLanguages.map(l => ({ value: l.id, label: l.language })),
        nationalQualifierById: nationalQualifiers.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        nationalQualifierOptions: nationalQualifiers.map(q => ({ value: q.id, label: q.description }))
      };
    }
    case "setBroadcastRequests":
      return {
        ...state,
        broadcastRequests: broadcastRequests.sort((a, b) => {
          const aGame = a.primaryGame || a.secondaryGame;
          const bGame = b.primaryGame || b.secondaryGame;
          const dateRes = compareValue(aGame?.dateTimeUtc, bGame?.dateTimeUtc);
          if (dateRes !== 0) {
            return dateRes;
          }

          return compareValue(aGame?.homeTeamId, bGame?.homeTeamId);
        })
      };
    case "saveBroadcastRequest": {
      const { broadcastRequest } = action;

      if (!broadcastRequest) {
        return state;
      }

      return {
        ...state,
        broadcastRequests: upsert(broadcastRequest, state.broadcastRequests, b => b.id === broadcastRequest.id)
      };
    }
    case "deleteBroadcastRequest": {
      return {
        ...state,
        broadcastRequests: state.broadcastRequests.filter(b => b.id !== action.broadcastRequest?.id)
      };
    }
    default:
      return state;
  }
};

const BroadcastProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <BroadcastContext.Provider value={{ ...state, dispatch }}>{children}</BroadcastContext.Provider>;
};

export { BroadcastContext, BroadcastProvider };
