import { useCallback, useContext, useMemo } from "react";
import { BATTER_ROLES, BatterRole } from "@/constants/user";
import { AuthContext } from "@/contexts/auth";

export const useAuth = () => {
  // hooks
  const { userInfo, ...rest } = useContext(AuthContext);
  const { permissions = [] } = userInfo;

  // constants
  const userRoles = useMemo(
    () => new Set(permissions.filter(p => p.startsWith("BATTER_"))) as Set<BatterRole>,
    [permissions]
  );

  // functions
  const hasRole = useCallback(
    (...roles: BatterRole[]) => !!userRoles.size && roles.some(r => userRoles.has(r)),
    [userRoles]
  );

  const notRole = useCallback(
    (...roles: BatterRole[]) => !!userRoles.size && !roles.some(r => userRoles.has(r)),
    [userRoles]
  );

  const hasOnly = useCallback(
    (role: BatterRole) => !BATTER_ROLES.filter(r => r !== role && userRoles.has(r)).length,
    [userRoles]
  );

  return { ...rest, userInfo, hasRole, notRole, hasOnly };
};
