/* eslint-disable no-useless-escape */
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import { GameConstants } from "@/constants/game";
import { BroadcastSource } from "@/types/broadcast";
import { WeekValue } from "@/types/core";
import { SportGroup } from "@/types/game";
import { DivisionOption, GroupLeagueOption, LeagueDivision } from "@/types/league";
import { Season } from "@/types/schedule";
import { ValueOpt } from "@/types/select";
import { GroupTeamOption, TimezoneOption } from "@/types/team";
import { TimezoneMap } from "@/types/timezones";
import { convertNetworkToDisplayName } from "./broadcast";
import { addDays, BatterDate, BatterTime, formatDate, getNextDayOfWeekOfDate, getPrevDayOfWeekOfDate } from "./date";

const dateOrUndefined = (date?: Date | null) => (!date ? undefined : new BatterDate(date));

const timeOrUndefined = (time?: Date | null) => (!time ? undefined : new BatterTime(time));

const createValuesList = <T>(values: ValueOpt<T>[]) => values.map(v => v.value).filter((v): v is T => v !== null);

const toOptions = (...args: string[]): ValueOpt<string>[] => args.map(v => ({ label: v, value: v }));

/**
 * check if an email is a valid email (RFC)
 * @param email email to test
 * @returns true if the email is valid
 */
const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

const isUrlValid = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

const isPhoneNumberValid = (value: string, countryCode: CountryCode = "US") => isValidPhoneNumber(value, countryCode);

const divisionOptionCreator = (d: LeagueDivision): DivisionOption => ({
  label: d.shortName,
  value: d.id,
  division: d
});

const timezoneOptionCreator = (tz: TimezoneMap): TimezoneOption => ({
  label: tz.longName,
  value: tz.id,
  timezone: tz
});

const broadcastSourceOptionCreator = (s: BroadcastSource): ValueOpt<number> => ({
  label: `${s.description}${s.teamName ? " (" + s.teamName + ")" : ""}`,
  value: s.id
});

const broadcastBucketOptionCreator = (s: BroadcastSource): ValueOpt<number> => {
  const description = convertNetworkToDisplayName(s.description);
  return {
    label: `${description}${s.teamName ? " (" + s.teamName + ")" : ""}`,
    value: s.id
  };
};

const filterTeamOptionsBySport = (options: GroupTeamOption[], sport: SportGroup) => {
  // create sportIds
  const sportIds = new Set<number>();
  if (sport === "MLB") {
    sportIds.add(GameConstants.SportIds.MLB);
  } else if (sport === "MiLB") {
    GameConstants.SportIds.MiLB.forEach(sportId => sportIds.add(sportId));
  } else if (sport === "WBC") {
    sportIds.add(GameConstants.SportIds.INTERNATIONAL);
  }

  if (!sportIds.size) {
    return [];
  }

  return options.reduce((pv, option) => {
    const currOptions = option.options.filter(o => sportIds.has(o.team?.sportId || -1));
    if (currOptions.length) {
      return [...pv, { ...option, options: currOptions }];
    }
    return [...pv];
  }, [] as GroupTeamOption[]);
};

const filterTeamOptionsByLeagueIds = (options: GroupTeamOption[], leagueIds: number[]) => {
  const ids = new Set(leagueIds);

  return options.reduce((pv, option) => {
    const currOptions = option.options.filter(o => ids.has(o.team?.leagueId || -1));
    if (currOptions.length) {
      return [...pv, { ...option, options: currOptions }];
    }
    return [...pv];
  }, [] as GroupTeamOption[]);
};

const filterLeagueOptionsBySport = (options: GroupLeagueOption[], sport: SportGroup) => {
  // create sportIds
  const sportIds = new Set<number>();
  if (sport === "MLB") {
    sportIds.add(GameConstants.SportIds.MLB);
  } else if (sport === "MiLB") {
    GameConstants.SportIds.MiLB.forEach(sportId => sportIds.add(sportId));
  } else if (sport === "WBC") {
    sportIds.add(GameConstants.SportIds.INTERNATIONAL);
  }

  if (!sportIds.size) {
    return [];
  }

  return options.reduce((pv, option) => {
    const currOptions = option.options.filter(
      o =>
        sportIds.has(o.league?.sportId || -1) &&
        (sport !== "WBC" || GameConstants.LeagueIds.WBC_IDS.has(o.league?.id || -1))
    );
    if (currOptions.length) {
      return [...pv, { ...option, options: currOptions }];
    }
    return [...pv];
  }, [] as GroupLeagueOption[]);
};

type WeekCreatorOptions = {
  startDay?: number;
};

const weekOptionCreator = (season: Season | null | undefined, options: WeekCreatorOptions = {}) => {
  // instance
  const results: ValueOpt<WeekValue>[] = [];

  if (!season) {
    return results;
  }

  const { regularSeasonStartDate: startDate, regularSeasonEndDate: endDate } = season;
  if (!startDate || !endDate) {
    return results;
  }

  // do-while: create ranges
  const { startDay = 0 } = options;
  const endDay = (startDay + 6) % 7;
  let date = startDate;
  let weekNumber = 1;
  const end = new Date(Math.min(getNextDayOfWeekOfDate(endDate, endDay).getTime(), endDate.getTime()));
  do {
    // create options
    const startOfWeek = getPrevDayOfWeekOfDate(date, startDay);
    const endOfWeek = getNextDayOfWeekOfDate(date, endDay);
    results.push({
      label: `Week ${weekNumber} (${formatDate(startOfWeek, "M/d/yy")} - ${formatDate(endOfWeek, "M/d/yy")})`,
      value: {
        startDate: startOfWeek,
        endDate: [endDate, endOfWeek].reduce((a, b) => (a < b ? a : b)),
        tbd: false,
        weekNumber
      }
    });

    // iterate
    weekNumber += 1;
    date = addDays(date, 7);
  } while (date < end);

  return results;
};

const cleanPhoneNumber = (phone: string) => phone.replace(/^\+1\s*/g, "");

export {
  broadcastBucketOptionCreator,
  broadcastSourceOptionCreator,
  cleanPhoneNumber,
  createValuesList,
  dateOrUndefined,
  divisionOptionCreator,
  filterLeagueOptionsBySport,
  filterTeamOptionsByLeagueIds,
  filterTeamOptionsBySport,
  isEmailValid,
  isPhoneNumberValid,
  isUrlValid,
  timeOrUndefined,
  timezoneOptionCreator,
  toOptions,
  weekOptionCreator
};
