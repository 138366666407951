import { ClientBroadcasterDayRestrictionForm, ClientBroadcasterFeedRuleForm } from "@/components/admin/igsp/types";
import { BadgeVariant } from "@/components/library/BatterBadge";
import { DayOfWeek } from "@/types/core";
import {
  IgspAdminSlug,
  IgspContractRuleViolations,
  IgspDecoderModel,
  IgspFeed,
  IgspFeedNetworkOption,
  IgspNetwork,
  IgspRequest,
  IgspRequestGame,
  IgspRequestStatus
} from "@/types/igsp";
import { ValueOpt } from "@/types/select";
import { isObjectEmpty } from "./core";
import { compareDates, formatDate, formatDateTime } from "./date";
import { compareLists } from "./list";
import { isBlank, testFields } from "./string";

const getDecoderName = (decoder: IgspDecoderModel) => {
  return `${decoder.brand.name} ${decoder.name}`;
};

const filterAdminTable = <T extends object>(
  slug: IgspAdminSlug,
  data: T[],
  filterText: string,
  active?: boolean | null
): T[] => {
  if (isBlank(filterText)) {
    return active != null
      ? data.filter(s => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const activeValue = slug === "network" ? (s as IgspNetwork).source?.isActive : ((s as any).active as boolean);
          return activeValue === active;
        })
      : data;
  }

  // with the filter text, search through ALL the different text fields in an object
  const text = filterText.trim();
  return data.filter(s => {
    if (slug === "network") {
      const network = s as IgspNetwork;
      return testFields([network.source?.description], text);
    }

    return testFields(
      Object.values(s).filter(v => typeof v === "string"),
      text
    );
  });
};

const compareDayRestrictions = (
  a: Record<DayOfWeek, ClientBroadcasterDayRestrictionForm>,
  b: Record<DayOfWeek, ClientBroadcasterDayRestrictionForm>
) => {
  // first check if the keys are the same
  if (!compareLists(Object.keys(a).sort(), Object.keys(b).sort())) {
    return false;
  }

  return compareLists(
    Object.values(a).sort((a, b) => a.day.localeCompare(b.day)),
    Object.values(b).sort((a, b) => a.day.localeCompare(b.day)),
    (a, b) =>
      a.fullyRestricted === b.fullyRestricted &&
      a.totalGames === b.totalGames &&
      a.beforeTime === b.beforeTime &&
      a.afterTime === b.afterTime
  );
};

const compareFeedRules = (a: ClientBroadcasterFeedRuleForm, b: ClientBroadcasterFeedRuleForm) => {
  return (
    a.gameType?.value === b.gameType?.value &&
    a.jewelEvent?.value === b.jewelEvent?.value &&
    a.homeTeam?.value === b.homeTeam?.value &&
    a.awayTeam?.value === b.awayTeam?.value &&
    !!compareDates(a.startDate, b.startDate) &&
    !!compareDates(a.endDate, b.endDate) &&
    compareLists(a.feeds, b.feeds, (a, b) => a.value === b.value)
  );
};

const getStatusVariant = (status: IgspRequestStatus): BadgeVariant => {
  switch (status) {
    case "ENGINEERING":
      return "warning";
    case "FINALIZED":
      return "success";
    case "DENIED":
      return "danger";
    case "PENDING":
      return "amber";
    case "PRE_FINALIZED":
      return "primary";
    default:
      return "secondary";
  }
};

const parseRequest = (request?: IgspRequest | null) => {
  if (!request) {
    return "";
  }

  const { gameDate, dateTimeUtc, homeTeam, awayTeam } = request.game;
  const date = formatDate(gameDate, "EEE M/d");
  const time = dateTimeUtc ? formatDateTime(dateTimeUtc, "h:mma 'ET'", { toZone: "America/New_York" }) : "";

  const prefix = !date && !time ? "TBD" : `${date || "TBD"} ${time ? time + "ET" : "TBD"}`;
  const suffix = `${awayTeam.abbrev} @ ${homeTeam.abbrev}`;

  return `${prefix} ${suffix}`;
};

const areViolationsPresent = (x?: IgspContractRuleViolations | null): x is IgspContractRuleViolations => {
  if (!x) {
    return false;
  }

  const { hasContract: _, hasViolations, ...rest } = x;
  return hasViolations && !isObjectEmpty(rest);
};

const createIgspNetworkOption = (network: IgspNetwork): ValueOpt<number> => ({
  value: network.id,
  label: network.source.description
});

const createIgspFeedNetworkOption = (value: {
  network?: IgspNetwork | null;
  feed?: IgspFeed | null;
}): IgspFeedNetworkOption<number> | null => {
  const { network, feed } = value;
  if (network) {
    return {
      value: network.id,
      label: network.source.description,
      type: "NETWORK"
    };
  }

  if (feed) {
    return {
      value: feed.id,
      label: feed.name,
      type: "FEED"
    };
  }

  return null;
};

const formatIgspMatchup = (game?: IgspRequestGame | null) => {
  if (!game) {
    return "";
  }

  const { homeTeam, awayTeam } = game;
  return `${awayTeam.abbrev} @ ${homeTeam.abbrev}`;
};

const formatMatchupTimestamp = ({
  date,
  dateTime,
  dateFormat = "EEE M/d/yyyy",
  format = "EEE M/d/yyyy h:mm a 'ET'"
}: {
  date?: string | null;
  dateTime?: string | null;
  format?: string;
  dateFormat?: string;
}) => {
  return dateTime
    ? formatDateTime(dateTime, format, { toZone: "America/New_York" })
    : formatDate(date, dateFormat, "TBD");
};

const formatIgspGame = (game?: IgspRequestGame | null) => {
  if (!game) {
    return "";
  }

  const { gameDate, dateTimeUtc } = game;
  return `${formatMatchupTimestamp({ date: gameDate, dateTime: dateTimeUtc, format: "M/d h:mm a 'ET'", dateFormat: "M/d" })} ${formatIgspMatchup(game)}`;
};

type FormatIgspGameTimestampOptions = {
  hideDate?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  defaultValue?: string;
};

const formatIgspGameTimestamp = (
  game: IgspRequestGame | null | undefined,
  options: FormatIgspGameTimestampOptions = {}
) => {
  const { hideDate, dateFormat = "M/d", timeFormat = "h:mma 'ET'", defaultValue = "" } = options;
  if (!game) {
    return { date: defaultValue, time: defaultValue };
  }

  const utcTs = game.dateTimeUtc;
  const date = !hideDate ? formatDate(game.gameDate, dateFormat) : defaultValue;
  const time = utcTs ? formatDateTime(utcTs, timeFormat, { toZone: "America/New_York" }) : defaultValue;
  return { date, time };
};

export {
  areViolationsPresent,
  compareDayRestrictions,
  compareFeedRules,
  createIgspFeedNetworkOption,
  createIgspNetworkOption,
  filterAdminTable,
  formatIgspGame,
  formatIgspGameTimestamp,
  formatIgspMatchup,
  formatMatchupTimestamp,
  getDecoderName,
  getStatusVariant,
  parseRequest
};
