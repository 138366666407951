import { useContext, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { AlertContext } from "../contexts/alert";
import { Fade } from "./library/@primitives/fade";
import { BatterAlert } from "./library/BatterAlert";

const FadeAlert = styled(Fade)`
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  pointer-events: auto;
`;

const Content = styled.div`
  font-size: 0.875rem;
  letter-spacing: -0.35px;
  width: 100%;
  padding-right: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

const Alert = styled(BatterAlert)`
  max-width: 400px;
  max-height: 150px;
  overflow-y: auto;
`;

const GlobalAlert = () => {
  // hooks
  const timeout = useRef(0);
  const { visible, message, variant, interval, dispatch } = useContext(AlertContext);

  // effects
  // handle show/hide of alert
  useEffect(() => {
    if (visible) {
      window.clearTimeout(timeout.current);

      if (interval > 0) {
        timeout.current = window.setTimeout(() => {
          dispatch({ type: "hideAlert" });
        }, interval);
      }
    }
  }, [visible, interval, dispatch]);

  return createPortal(
    <FadeAlert in={visible} unmountOnExit>
      <Alert
        dismissable
        id="global-alert"
        variant={variant}
        onRemove={() => {
          window.clearTimeout(timeout.current);
          dispatch({ type: "hideAlert" });
        }}
      >
        <Content>
          {message.split("\n").map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </Content>
      </Alert>
    </FadeAlert>,
    document.body
  );
};

export default GlobalAlert;
