import { SportGroup } from "../types/game";
import { Season } from "../types/schedule";

/**
 * This is a port of the angular function. This gets the current season
 * for the particular sport by finding the season that matches the current
 * year. This may change to get the most recent RELEASED season that
 * matches the sport.
 * @param sport sport
 * @param seasons list of seasons
 * @returns the current season, if it exists
 */
const getCurrentSeason = (sport: SportGroup, seasons: Season[]) => {
  const year = new Date().getFullYear();
  return (
    seasons.find(({ sport: seasonSport, regularSeasonStartDate }) => {
      return sport === seasonSport && !!regularSeasonStartDate && regularSeasonStartDate.getFullYear() === year;
    }) || null
  );
};

/**
 * This gets the most recent post season
 * @param sport sport
 * @param seasons list of seasons
 * @returns the current post season if it exists
 */
const getCurrentPostSeason = (sport: SportGroup, seasons: Season[]) => {
  // sort the seasons list in reverse order for the sport
  const arr = seasons.filter(s => s.sport === sport).sort((a, b) => b.year - a.year);
  return arr.find(s => !!s.postSeasonStartDate && s.postSeasonEndDate) || null;
};

/**
 * This gets the most recent spring training season
 * @param sport sport
 * @param seasons list of seasons
 * @returns the current spring training season if it exists
 */
const getCurrentSpringTraining = (sport: SportGroup, seasons: Season[]) => {
  // sort the seasons list in reverse order for the sport
  const arr = seasons.filter(s => s.sport === sport).sort((a, b) => b.year - a.year);
  return arr.find(s => !!s.springTrainingStartDate && s.springTrainingEndDate) || null;
};

export { getCurrentPostSeason, getCurrentSeason, getCurrentSpringTraining };
