const _daysOfTheWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"] as const;
type DayOfWeek = (typeof _daysOfTheWeek)[number];
const DAYS_OF_THE_WEEK = _daysOfTheWeek as unknown as DayOfWeek[];

type Hydrates = {
  hydrates?: string[];
};

type UserInfo = {
  accessToken: { accessToken: string };
  userName?: string;
  userFullName?: string;
  email?: string;
  orgId?: number;
  orgName?: string;
  orgShortName?: string;
  userId?: number;
  permissions?: string[];
};

type RoleInfo = {
  adminUser: boolean;
  assignedClub: boolean;
  broadcastUser: boolean;
  broadcastRequester: boolean;
  broadcastViewerUser: boolean;
  clubUser: boolean;
  externalUser: boolean;
  fieldUser: boolean;
  milbUser: boolean;
  minorClubUser: boolean;
  minorClubSupervisorUser: boolean;
  scheduleUser: boolean;
  viewerUser: boolean;
  igspUser: boolean;
  igspAdminUser: boolean;
  igspRequesterUser: boolean;
  igspViewerUser: boolean;
  igspRequestApproverUser: boolean;
  igspEngineeringApproverUser: boolean;
  roles: Set<string>;
};

type BatterUserInfo = {
  name: string;
  username: string;
  roles: string[];
};

type BatterApiError = {
  error: string;
  errors?: unknown[];
  message: string;
  path: string;
  status: number;
  timestamp: string;
  trace?: string;
};

type BatterDatePickerValue = {
  startDate?: Date | null;
  endDate?: Date | null;
  tbd: boolean;
};

type ElementType<T> = React.ReactElement<T, string | React.JSXElementConstructor<T>>;

type ViewType = "calendar" | "table" | "grid";

type WeekValue = BatterDatePickerValue & {
  weekNumber: number;
};

export {
  DAYS_OF_THE_WEEK,
  type BatterApiError,
  type BatterDatePickerValue,
  type BatterUserInfo,
  type DayOfWeek,
  type ElementType,
  type Hydrates,
  type RoleInfo,
  type UserInfo,
  type ViewType,
  type WeekValue
};
